<template>
    <span class="ui-labelModal">
        <span @click="onShowModal">
            <slot>
                <a-button type="primary" class="ui-member">
                    <div>
                        <span>选择标签</span>
                    </div>
                </a-button>
            </slot>
        </span>

        <!-- 新建标签组的弹框 -->
        <a-modal v-model:visible="showLabel" width="780px" destroyOnClose title="选择标签">
            <template #footer>
                <div style="display: flex;justify-content: space-between;">
                    <div style="padding-top: 8px;">
                        <a v-permission="['member_label_list_addClassify']" @click="onAddNewLabelGroup">
                            <Icon icon="PlusOutlined"/>
                            新建标签分类
                        </a>
                    </div>
                    <div>
                        <a-button type="info" @click="onLabelClose">取消</a-button>
                        <a-button type="primary" @click="onOk">确定</a-button>
                    </div>
                </div>
            </template>
            <div class="ui-labelModal ui-labelModal__list">
                <a-spin :spinning="loading">
					<a-form ref="formRef" :model="modelRef" @finish="onSearch">
						<a-row type="flex">
							<a-form-item label="标签名称" name="title">
								<a-input v-model:value="modelRef.title" placeholder="请输入"/>
							</a-form-item>
							<a-form-item name="classifyId" label="标签分类" style="margin-left: 30px;">
								<a-select style="width:180px;" v-model:value="modelRef.classifyId" placeholder="请选择">
									<a-select-option v-for="item in classifyList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
								</a-select>
							</a-form-item>
							<div style="flex: 1;text-align: right;">
								<a-button style="margin-right: 20px;" type="primary" html-type="submit">搜索</a-button>
								<a-button @click="resetBtn">重置</a-button>
							</div>
						</a-row>
					</a-form>
					
					<div style="margin-top: 10px;">
						<a-alert type="info" show-icon>
							<template #message>
								<div>已选择{{ labelSelectKeyList.length }}项</div>
							</template>
							<template #closeText>
								<!-- <a-button size="small" @click.stop="onClear">清空</a-button> -->
							</template>
						</a-alert>
					</div>
					
                    <div class="ui-labelGroup__item" v-for="(item, index) in labelList" :key="index">
                        <div class="ui-label__name">{{ item.classifyTitle }}：<a-checkbox @change="(e) => onAllChange(e, item)">全选</a-checkbox></div>
                        <div class="ui-label__box">
                            <a-tag v-permission="['member_label_list_addLabel']" class="ui-tag" @click="onAddLabel(index)">
                                <Icon icon="PlusOutlined"/>
                                添加
                            </a-tag>
                            <div v-if="labelAddShowInput == index" style="display: inline-block;margin-right: 18px;">
                                <a-input style="width: 150px;" class="ui-input" placeholder="输入后回车" v-model:value="tagText"
                                         @blur="onLabelAddBlur"
                                         @pressEnter="onLabelAddOk(item)"></a-input>
                            </div>
                            <a-checkbox
                                    class="ui-tag"
                                    :checked="labelSelectKeyList.indexOf(subItem.id) != -1"
                                   v-for="(subItem, subIndex) in item.tagList" :key="subItem.id" @click="onSelectLabel(subItem, subIndex)">{{
                                subItem.title }}
                            </a-checkbox>
                        </div>
                    </div>
					<!-- <div style="margin-top: 20px;text-align: right;">
					 <a-pagination
					      v-model:current="pagination.current"
					      v-model:page-size="pagination.pageSize"
					      :total="pagination.totalCount"
						  showSizeChanger
						  showQuickJumper
					      :show-total="total => `总共 ${total} 条`"
						  @change="onPageChange"
						  @showSizeChange="onShowSizeChange"
					    />
						</div> -->
                </a-spin>
            </div>
        </a-modal>

        <!-- 新建标签组弹窗 -->
        <a-modal title="新建标签分类" width="600px" v-model:visible="showLabelGroup"
                 @ok="onAddLabelGroupAddOk">
            <div class="ui-labelModal">

                <div class="ui-form__item">
                    <span class="ui-form__label">分类名称:</span>
                    <a-input style="width: 310px;"
                             v-model:value="labelGroupName"
                             placeholder="请输入标签组名称"></a-input>
                </div>
				
				<div class="ui-form__item">
				    <span class="ui-form__label">排序:</span>
				    <a-input-number
				             v-model:value="sort"
							 :min="0"
				             placeholder="请输入"></a-input-number>
				</div>

                <!-- <div class="ui-form__item">
                    <span class="ui-form__label">标签名称:</span>
                    <div>
                        <div v-for="(item,index) in newLabel" :key="index" style="margin-bottom: 10px;">
                            <a-input v-model:value="newLabel[index]" style="width: 310px;margin-right: 10px;"
                                     placeholder="请输入标签名称" autoFocus focus></a-input>
                            <Icon v-if="newLabel.length > 1" icon="CloseCircleOutlined" @click="onRemoveLabelGroupInput(index)"/>
                        </div>
                    </div>
                </div>

                <a style="margin-left: 120px;" @click="onAddLabelGroupInput">
                    <Icon icon="PlusOutlined"/>
                    添加标签</a> -->
            </div>
        </a-modal>
    </span>
</template>

<script>
    import { Icon } from "@/components/icon/icon.js";
	import * as labelApi from '@/service/modules/label.js';
    export default {
        props: {
            value: {
                type: Array,
                default: ()=> {
                    return [];
                }
            },
            multiple: {
                type: Boolean,
                default: true
            },
			selectItem: {
				type: Array,
				default: () => {
				    return []
				}
			},
        },
		watch: {
			selectItem: {
				handler(newVal) {
					this.labelSelectList = JSON.parse(JSON.stringify(newVal));
				},
				deep: true,
				immediate: true
			}
		},
        data() {
            return {
                loading: false,
				classifyList: [],
				modelRef: {
					classifyId: undefined,
					title: ''
				},
				searchData: {},
                showLabel: false,
                showLabelGroup: false,
                labelAddShowInput: null,
                labelSelectKeyList: [],
                labelSelectList: [],
                tagText: '',
                newLabel: [''],
                labelList: [],
                labelGroupName: '',
				sort: 0,
				pagination: {
					current: 1,
					pageSize: 20,
					totalCount: 0
				}
            }
        },
        components: { Icon },
        created() {
            this.labelSelectKeyList = JSON.parse(JSON.stringify(this.value));
        },
        methods: {
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.modelRef));
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await labelApi.getTagList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					title: this.searchData.title,
					classifyId: this.searchData.classifyId
				});
				this.loading = false;
				if (ret.code === 200) {
					this.labelList = ret.data.list;
					this.pagination.totalCount = ret.data.totalCount;
				}
			},
			async getClassifyList () {
				let ret = await labelApi.getTagClassifyList({});
				if (ret.code === 200) {
					this.classifyList = ret.data;
				}
			},
			resetBtn() {
			  this.$refs.formRef.resetFields();
			  this.onSearch();
			},
            onShowModal() {
                // this.getLabelList();
				this.getClassifyList();
				this.getData();
                this.showLabel = true;
                this.labelSelectKeyList = JSON.parse(JSON.stringify(this.value));
            },
            async getLabelList() {
				this.loading = true;
				let ret = await labelApi.getTagList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					title: this.searchData.title,
					classifyId: this.searchData.classifyId
				});
				this.loading = false;
				if (ret.code === 200) {
					this.labelList = ret.data.list;
					this.pagination.totalCount = ret.data.totalCount;
				}
            },
            onAllChange(e, item) {
                let checked = e.target.checked;
                if (checked) {
                    item.tagList.forEach(subItem => {
                        if (!this.labelSelectKeyList.includes(subItem.id)) {
                            this.labelSelectKeyList.push(subItem.id);
                            this.labelSelectList.push(subItem);
                        }
                    })
                } else {
                    item.tagList.forEach(subItem => {
                        if (this.labelSelectKeyList.includes(subItem.id)) {
                            let index = this.labelSelectKeyList.indexOf(subItem.id);
                            this.labelSelectKeyList.splice(index, 1);
                            this.labelSelectList.splice(index, 1);
                        }
                    })
                }
            },
            onAddNewLabelGroup: function () {
                this.labelGroupName = '';
                this.newLabel = [''];
                this.showLabelGroup = true;
            },
            onAddLabel: function (type) {
                this.labelAddShowInput = type;
            },
            onLabelAddBlur: function () {
                if (!this.tagText) {
                    this.labelAddShowInput = null;
                }
            },
			onClear() {
				this.$confirm({
					title: '提升',
					content: '是否清除？',
					onOk:() => {
						this.labelSelectKeyList = [];
						this.labelSelectList = [];
					}
				})
			},
            onLabelAddOk: async function (item) {
                let list = item.tagList.filter((subItem) => {
                    return subItem.title == this.tagText;
                });
                if(!this.tagText || list.length > 0) {
                    this.$message.warn('请检查填入标签是否为空或存在重复');
                    return;
                }
				this.loading = true;
				let ret = await labelApi.saveTag({
					title: this.tagText,
					classifyId: item.classifyId
				});
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功');
					this.labelAddShowInput = null;
					this.tagText = '';
					this.getData();
				}
            },
            onAddLabelGroupAddOk: async function () {
                if(!this.labelGroupName) {
                   this.$message.warn('请输入标签分类名称');
                    return;
                }
				this.loading = true;
				let ret = await labelApi.saveTagClassify({
					type: 2,
					level:1,
					title: this.labelGroupName,
					sort: this.sort
				})
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功');
					this.labelGroupName = '';
					this.sort = 0;
					this.showLabelGroup = false;
					this.getData();
				}
            },
            onRemoveLabelGroupInput: function (index) {
                this.newLabel.splice(index, 1);
            },
            onAddLabelGroupInput: function () {
                this.newLabel.push('')
            },
            onSelectLabel (item) {
                if (this.multiple) {
                    if (this.labelSelectKeyList.indexOf(item.id) == -1) {
                        this.labelSelectKeyList.push(item.id);
                        this.labelSelectList.push(JSON.parse(JSON.stringify(item)));
                    } else {
                        let index = this.labelSelectKeyList.indexOf(item.id);
                        this.labelSelectKeyList.splice(index, 1);
                        this.labelSelectList.length && this.labelSelectList.splice(index, 1);
                    }
                } else {
                    if (this.labelSelectKeyList.length && this.labelSelectKeyList[0] === item.id) {
                        this.labelSelectKeyList = [];
                        this.labelSelectNewList = [];
                    } else {
                        this.labelSelectKeyList = [item.id];
                        this.labelSelectNewList = [JSON.parse(JSON.stringify(item))];
                    }
                }
            },
            onLabelClose() {
                this.showLabel = false;
                this.labelSelectKeyList = JSON.parse(JSON.stringify(this.value));
                this.labelSelectList = [];
            },
            onOk: function() {
                this.$emit('update:value', this.labelSelectKeyList);
                this.$emit('change', this.labelSelectList);
				this.showLabel = false;
            },
			onPageChange(page) {
				this.pagination.current = page;
				this.getData();
			},
			onShowSizeChange(page, pageSize) {
				this.pagination.current = 1;
				this.pagination.pageSize = pageSize;
				this.getData();
			}
        }
    }
</script>

<style scoped>
.ui-form__item {
    display: flex;
    margin:10px 20px;
}
.ui-form__label {
    display: inline-block;
    width: 100px;
    padding-right: 10px;
    line-height: 32px;
    text-align: right;
}
.ui-department__selected {
    margin-top: 8px;
}
.ui-label__name {
    margin: 8px 0;
}
.ui-tag {
    line-height: 28px;
    height: auto;
    margin-bottom: 5px;
    margin-right: 20px;
    border-radius: 0;
    background-color: transparent;
	cursor: pointer;
}
.ui-label__selectAction {
    color: #1890ff;
    background: #e7f7ff;
    border: 1px solid #1890ff;
}
.ui-label__box {
    margin: 0 20px;
}
.ui-labelGroup__item {
	padding: 10px;
	border-bottom: 1px dashed #EEE;
}
.ui-labelModal__list {
	height: 600px;
	overflow: auto;
}
</style>